<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="
            col-7
            p-0
            align-self-center
            font-weight-bold
            d-flex
            align-items-center
          "
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.rooms_number") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="mr-2"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
          </div>
        </div>
        <div
          class="
            col-5
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <el-button
            class="mr-2"
            size="mini"
            @click="drawerCreate = true"
            icon="el-icon-circle-plus-outline"
          >
            {{ $t("message.create") }}
          </el-button>
          <export-excel
            class="btn excel_btn mr-2"
            :data="list"
            worksheet="Номер комнаты"
            name="Номер комнаты.xls"
          >
            <el-button size="mini" icon="el-icon-document-delete">
              Excel
            </el-button>
          </export-excel>
          <crm-column-settings
            :columns="columns"
            :modelName="'rooms'"
            @c-change="updateColumn"
          ></crm-column-settings>
        </div>
      </div>

      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0"
        v-loading="loadingData"
      >
        <thead>
          <tr>
            <crm-th :column="columns.id" :sort="sort" @c-change="updateSort">{{
              columns.id.title
            }}</crm-th>
            <crm-th
              :column="columns.name"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.name.title }}</crm-th
            >
            <crm-th
              :column="columns.room_number"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.room_number.title }}</crm-th
            >
            <crm-th
              :column="columns.created_at"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.created_at.title }}</crm-th
            >
            <crm-th
              :column="columns.updated_at"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.updated_at.title }}</crm-th
            >
            <crm-th
              :column="columns.settings"
              :sort="sort"
              @c-change="updateSort"
              >{{ columns.settings.title }}</crm-th
            >
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              >
              </el-input>
            </th>
            <th v-if="columns.room_number.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.room_number"
                :placeholder="columns.room_number.title"
              >
              </el-input>
            </th>

            <th v-if="columns.created_at.show">
              <el-date-picker
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
                :placeholder="columns.created_at.title"
                v-model="filterForm.created_at"
                size="mini"
              >
              </el-date-picker>
            </th>
            <th v-if="columns.updated_at.show">
              <el-date-picker
                :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
                :placeholder="columns.updated_at.title"
                v-model="filterForm.updated_at"
                size="mini"
              >
              </el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr v-for="room in rooms" :key="room.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ room.id }}</td>
            <td v-if="columns.name.show">{{ room.name }}</td>
            <td v-if="columns.room_number.show">{{ room.room_number }}</td>
            <td v-if="columns.created_at.show">
              {{ room.created_at  }}
            </td>
            <td v-if="columns.updated_at.show">
              {{ room.updated_at  }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                name="room"
                :model="room"
                :actions="actions"
                @edit="edit"
                @delete="destroyModel"
              ></crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>

      <el-pagination
        @current-change="handleCurrentChange"
        class="mt-3 mypagination"
        background
        layout="prev, pager, next"
        :page-size="pagination.per_page"
        :total="pagination.total"
      >
      </el-pagination>

      <!-- Creating Room model  -->
      <el-drawer
        class="popups_title"
        :visible.sync="drawerCreate"
        @close="reloadIfChanged"
        size="80%" :wrapperClosable="false"
      >
        <AddRoom
          :reloadModel="reopenUpdate"
          @c-close="reloadIfChanged('drawerCreate')"
        ></AddRoom>
      </el-drawer>
      <!-- Creating Room model  -->
      <!-- Updating Room model  -->
      <el-drawer
        class="popups_title"
        :visible.sync="drawerUpdate"
        size="80%" :wrapperClosable="false"
        @open="reopenUpdate = true"
        @close="reloadIfChanged('reopenUpdate')"
      >
        <UpdateRoom
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
        ></UpdateRoom>
      </el-drawer>
      <!-- Updating Room model  -->
    </div>
  </div>
</template>    
<script>
import AddRoom from "./create";
import UpdateRoom from "./update";
import { mapGetters, mapActions, mapState } from "vuex";
import { log } from "util";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  data() {
    return {
      json_fields: {
        "№": name,
        Наименование: "",
        Имя: "",
        Телефон: "",
        Создан: "",
      },
      loadingData: false,
      drawerCreate: false,
      drawerUpdate: false,
      reopenUpdate: false,
      rooms: [],
    };
  },
  computed: {
    ...mapGetters({
      roles: "roles/list",
      list: "rooms/list",
      columns: "rooms/columns",
      pagination: "rooms/pagination",
      filter: "rooms/filter",
      sort: "rooms/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  watch: {},
  async created() {},
  methods: {
    ...mapActions({
      loadRoles: "roles/index",
      updateList: "rooms/index",
      setSearch: "rooms/setSearch",
      updatePagination: "rooms/updatePagination",
      updateSort: "rooms/updateSort",
      updateFilter: "rooms/updateFilter",
      updateColumn: "rooms/updateColumn",
      editModel: "rooms/show",
      delete: "rooms/destroy",
      refreshData: "rooms/refreshData",
    }),
    handleCurrentChange(val) {
      this.updatePagination({ key: "page", value: val })
        .then((res) => {})
        .catch((err) => {});
    },
    fetchData() {
      this.loadingData = true;
      const query = { ...this.filter, ...this.pagination, ...this.sort };

      this.updateList(query)
        .then((res) => {
          this.rooms = res.rooms;
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    async edit(model) {
      await this.editModel(model.id)
        .then(async (res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    destroyModel(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    AddRoom,
    UpdateRoom,
  },
};
</script>
